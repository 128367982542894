// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDS3wXlR_F_nLcyBRZUY_fsY-Vanb1mUBs",
  authDomain: "pov-iftar.firebaseapp.com",
  projectId: "pov-iftar",
  storageBucket: "pov-iftar.appspot.com",
  messagingSenderId: "204557336916",
  appId: "1:204557336916:web:01ebb6375a68d4064308c1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const projectFirestore = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service

const auth = getAuth(app);
export {auth, projectFirestore};