import React, {useState, useEffect} from 'react';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../firebase/config';
import {updateProfile} from 'firebase/auth'
import './RegisterScreen.css'

import logo from '../images/logo.png'

const RegisterScreen = () => {
    const navigate = useNavigate();
 
    const [numberFormat, setNumberFormat] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [ime, setIme] = useState('');
    const [prezime, setPrezime] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

 
    const onSubmit = async (e) => {
      e.preventDefault()
     
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;

            const nameValue = `${ime} ${prezime} ${phoneNum}`;

            updateProfile(auth.currentUser, {
            displayName: nameValue,
            }).then(() => {
                console.log("Name and surname successfully updated.");
            }).catch((error) => {
                console.log("Name and surname successfully NOT updated.");
            });
            
            console.log(user);
            navigate("/login")
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode);
            if(error.code === 'auth/invalid-email') setErrorMessage("Invalid email");
            else if(error.code === 'auth/invalid-password') setErrorMessage("Invalid password");
            else if(error.code === 'auth/missing-email') setErrorMessage("Missing email");
            else if(error.code === 'auth/missing-password') setErrorMessage("Missing password");
            else if(error.code === 'auth/weak-password') setErrorMessage("Weak password");
        });
    }
 
  return (
    <main >        
        <section>
            <div className="Page_register">
                <div>                  
                    <div className="header_login">
                        <Link to ='/'>
                        <img src={logo} className="logo" alt="logo" />
                        </Link>
                    </div>                                                                           
                    <div className='login_content'>
                    <form className='form_signup'>
                        <div className='question'>
                            <label className="login_label">
                                Ime
                            </label>
                            <input
                                value={ime}
                                onChange={(e) => setIme(e.target.value)} 
                                required                                 
                                placeholder="Ime"              
                            />
                        </div>  
                        <div className='question'>
                            <label className="login_label">
                                Prezime
                            </label>
                            <input
                                value={prezime}
                                onChange={(e) => setPrezime(e.target.value)} 
                                required                                 
                                placeholder="Prezime"              
                            />
                        </div> 
                        <div className='question'>
                            <label className="login_label">
                                Broj telefona <br />
                            </label>
                            <input
                                value={phoneNum}
                                onChange={(e) => setPhoneNum(e.target.value)} 
                                required                                 
                                placeholder="061 111 111"              
                            />
                        </div>                                                                                                  
                        <div className='question'>
                            <label className="login_label">
                                Email adresa
                            </label>
                            <input
                                type="email"
                                label="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}  
                                required                                    
                                placeholder="Email adresa"                                
                            />
                        </div>

                        <div className='question'>
                            <label className="login_label">
                                Šifra
                            </label>
                            <input
                                type="password"
                                label="Create password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} 
                                required                                 
                                placeholder="Šifra"              
                            />
                        </div>                                             
                        {ime && prezime  && <button type="submit" className='button_login' onClick={onSubmit}>  
                            Dalje                              
                        </button>  }
                          
                        <p className='error_message'> {errorMessage} </p>                                      
                    </form>
                    <p className='register_label'>
                        Već imate račun?{' '}
                        <NavLink to="/login" >
                            Prijavi se
                        </NavLink>
                    </p> 
                    </div>                  
                </div>
            </div>
        </section>
    </main>
  )
}
 
export default RegisterScreen