import React, {useState} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../firebase/config';
import { NavLink, useNavigate, Link } from 'react-router-dom'
import './LoginScreen.css'

import logo from '../images/logo.png'
 
const LoginScreen = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
       
    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/home")
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            if(error.code === 'auth/invalid-email') setErrorMessage("Invalid email");
            else if(error.code === 'auth/invalid-password') setErrorMessage("Invalid password");
            else if(error.code === 'auth/missing-email') setErrorMessage("Missing email");
            else if(error.code === 'auth/missing-password') setErrorMessage("Missing password");
            else if(error.code === 'auth/invalid-credential') setErrorMessage("Invalid email or password");
        });
       
    }
 
    return(
    <main >        
        <section>
            <div className='Page'>                                            
                <div className="header_login">
                    <Link to ='/'>
                    <img src={logo} className="logo" alt="logo" />
                    </Link>
                </div>
                <div className='login_content'>
                <form className='form_signup'>                                              
                    <div className='question'>
                        <label className="login_label">
                            Email adresa
                        </label>
                        <input
                            id="email-address"
                            name="email"
                            type="email"                                    
                            required                                                                                
                            placeholder="Email adresa"
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    </div>

                    <div className='question'>
                        <label className="login_label">
                            Šifra
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"                                    
                            required                                                                                
                            placeholder="Šifra"
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>
                                            
                    <div>
                        <button className='button_login' onClick={onLogin}>      
                            Prijavi se                                                                   
                        </button>
                    </div>     
                    <p className='error_message'> {errorMessage} </p>                           
                    </form>
                    
                    <p className="register_label">
                        Nemate račun? {' '}
                        <NavLink to="/">
                            Registruj se
                        </NavLink>
                    </p>                                
                </div>
                                            
            </div>
        </section>
    </main>
    )
}
 
export default LoginScreen