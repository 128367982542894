import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import RegisterScreen from "./screens/RegisterScreen"
import LoginScreen from "./screens/LoginScreen"
import MainScreen from "./screens/MainScreen"
import CameraScreen from "./screens/CameraScreen"
import GalleryScreen from "./screens/GalleryScreen"
import AboutScreen from "./screens/AboutScreen"

import './App.css';

function App() {


  return (
    <BrowserRouter>
    <Routes>
        <Route index element={<RegisterScreen />} />
        <Route exact path={"/login"} element={<LoginScreen />} />
        <Route path={"/home"} element={<MainScreen />} />
        <Route path={"/camera"} element={<CameraScreen />} />
        <Route path={"/gallery"} element={<GalleryScreen />} />
        <Route exact path={"/about"} element={<AboutScreen />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
