import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { auth } from '../firebase/config';

import logo from '../images/logo.png'
import camera from '../images/camera.png'
import decorations from '../images/decorations.png'
import Gallery from '../images/Gallery.png'
import lens from '../images/lens.png'
import Polaroid from '../images/Polaroid.png'
import './MainScreen.css'


const MainScreen = () => {
  const homePageText = ["Obuj moje cipele", <br /> , "prođi moje puteve,", <br /> , "preživi moje bolove,", <br /> , "pa tek onda možeš da mi sudiš.", <br /> ,];
  
  return(
    <div className="Page">
      <div className="background">
        <img src={decorations} className="decorations"></img>
        <div className="svjetlo"></div>
        <img src={camera} className="camera"></img>
      </div>
      <div className="App">
        <header className="header">
          <img src={logo} className="logo" alt="logo" />
        </header>
        <div className="mainContent">
          <div className="mainContentItem mainText">
            {homePageText}
          </div>
          <div className="mainContentItem mainMenu">
            {/* <Link to ='/camera' className="menuItem">
              <div className="menuCircle"> 
                <img className="menuIcon" src={Polaroid}/>
              </div>
              <p className="menuText">CAMERA</p>
            </Link> */}
            <div className="menuItem">
              <div className="menuCircle"> 
                <img className="menuIcon" src={Polaroid}/>
              </div>
              <p className="menuText">CAMERA</p>
            </div>
            <Link to ='/gallery' className="menuItem">
              <div className="menuCircle"> 
                <img className="menuIcon" src={Gallery}/>
              </div>
              <p className="menuText">GALLERY</p>
            </Link>
            <Link to ='/about' className="menuItem">
              <div className="menuCircle"> 
                <img className="menuIcon" src={lens}/>
              </div>
              <p className="menuText">POV</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
  };
  
  export default MainScreen;