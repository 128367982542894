import React, {useRef} from "react";
import { projectFirestore } from '../firebase/config';
import { addDoc, collection, serverTimestamp} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth } from '../firebase/config';

import "./CameraScreen.css"

import { Camera } from "react-camera-pro";

import { MdMotionPhotosOn } from "react-icons/md";
import { MdCameraswitch } from "react-icons/md";
import { IoClose } from "react-icons/io5";


const CameraScreen = () => {
  const navigate = useNavigate();
  const cameraRef = useRef(null);

  const author = auth.currentUser.displayName.split(' ').slice(0, 2).join(' ');;
  console.log(author);

  const handleCloseCamera = async () => {
    if (cameraRef.current) {
      const photo = await cameraRef.current.takePhoto();
      if(photo != null) navigate("/home");

      /* save image to firestore */
      const docRef = await addDoc(collection(projectFirestore, "images"), {
        createdAt: serverTimestamp(),
        url: photo,
        author: author
      });
      console.log("Document written with ID: ", docRef.id);
    }
  }

  const handleBack = async () => {
    navigate("/home");
  }

    return (
      <div className="Page">
        <div className="cameraContent">
          <Camera
            ref={cameraRef}
          />
          <div className="closeDiv">
             <IoClose  className="close_button" onClick={() => {handleBack();}}/>
          </div>
          <div className="cameraDiv">
            <MdMotionPhotosOn className="cameraItem" onClick={() => {handleCloseCamera();}} />
            <MdCameraswitch className="switchCamera" onClick={() => {cameraRef.current.switchCamera();}}/>
          </div>
          </div>
      </div>
    );
  };
  
  export default CameraScreen;