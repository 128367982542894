import React from 'react';
import useFirestore from '../hooks/useFirestore';
import { motion } from 'framer-motion';
import Masonry from "react-responsive-masonry"
import { Link } from "react-router-dom";
import './GalleryScreen.css'
import {useState} from "react";
import Modal from 'react-modal';

import { IoMdClose } from "react-icons/io";


import logo from '../images/logo.png'
import back_arrow from '../images/back_arrow.png'

const GalleryScreen = ({ setSelectedImg }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [author, setAuthor] = useState("");
  // const {docs} = useFirestore();
  //console.log(docs);

  const galleryQuote = ["Na linku ispod možete pronaći sve slike!", <br />];

  
  const openModal = (imageUrl, author) => {
    setSelectedImage(imageUrl);
    setAuthor(author);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery_page">
      <div className="header_style">
          <Link to ='/home'>
          <img src={logo} className="logo_camera" alt="logo" />
          </Link>
          <Link to ='/home'>
          <img src={back_arrow} className="headerBack" alt="back arrow" />
          </Link>
      </div>
      <div className="line"></div>
      {/* <div>
        <p className="gallery_quote">memories for a lifetime</p>
      </div>
      <div className="img-grids">
          <Masonry spacing={5}>
            {docs.map((item, index) => (
              <div
                key={index}
                style={{
                  height: index % 2 ? '200px' : '250px',
                  background: `url(${item.url}) center/cover`, // Assuming images should cover the div
                  margin: '5px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => openModal(item.url, item.author)}
              />
            ))}
          </Masonry>
          <Modal
            isOpen={selectedImage !== null}
            onRequestClose={closeModal}
            contentLabel="Image Modal"
            portalClassName="modal"
            >
            {selectedImage && <img src={selectedImage} alt="Selected" className="modal_image"/>}
            <div className='info'>
              <p className='author_info'> {author} </p>
              <IoMdClose onClick={closeModal} className='modal_close'></IoMdClose >
            </div>
          </Modal>
      </div> */}
      <div className="gallery_no_image">
       <p className="gallery_quote"> {galleryQuote} </p>
       <a href='https://drive.google.com/drive/folders/1NJv2WKrvNFgZ3G11j38pgWAsxMlYpCWB?usp=sharing' className="gallery_quote">LINK ZA GALERIJU</a>
      </div>
    </div>
  )
};
  
  export default GalleryScreen;