import React from "react";
import { Link } from "react-router-dom";
import logo from '../images/logo.png'

import './MainScreen.css'
import './AboutScreen.css'

import back_arrow from '../images/back_arrow.png'


const AboutScreen = () => {
  const aboutText = ["Empatija je esencijalna. Međutim, iako nam ona trenutno treba najviše, u svijetu zapažamo  pad iste. ", <br />, <br />, "To je sposobnost da zauzmemo poziciju i perspektivu druge osobe (ono što često nazivaju „stavljanje u tuđe cipele“) i pokušamo shvatiti kako se ta osoba osjeća ili šta proživljava. Volimo ljude koji pokušavaju razumjeti druge jer znaju da svaka osoba ima svoju priču i vodi vlastite borbe. Oni čine ovaj svijet ljepšim jer prihvataju druge onakvima kakvi jesu umjesto da ih osuđuju. Empatija je poput mišića koji treba vježbati - što je više koristimo, postajemo bolji. ", <br />, <br /> , "Dok razmišljamo o empatiji i povezivanju ljudi, ako imamo volju, pronaći ćemo mnogo načina kako da pokušamo odaslati poruku do određenih osoba. Čak i pored brojnih sredstava komunikacije, ne znači da uspijevamo u tome jer ćemo se bezbroj puta uvjeriti da kada na svoj društveni internetski profil (Facebook, Instagram, Twitter) stavite neki pisani ili zvučni sadržaj za nekoliko trenutaka će više osoba staviti svoj pristanak (tzv.like) a da ga uopće nisu stigli ni čuti ni pročitati.", <br />, <br /> , "Tema našeg iftara je POV (Point Of View) - ukoliko pokušate razumjeti drugu osobu, imate priliku upoznati druge tačke gledišta koje mogu upotpuniti vaše, omogućiti vam da otkrijete nova stajališta ili se jednostavno obogatite duhovno.", <br />, <br /> , "Zato želimo da na našem iftaru uspostavimo empatiju preko vaših slika. Zamolit ćemo vas da iskoriste ovu stranicu kako biste uhvatili one najljepše trenutke tokom današnjeg druženja te da na taj način date drugima da vide ovaj događaj „vašim očima“. ", <br />, <br /> , "Nadamo se da ćete uživati!"];
  
  return(
    <div className="Page about">
      <div className="header_style">
          <Link to ='/home'>
          <img src={logo} className="logo_camera" alt="logo" />
          </Link>
          <Link to ='/home'>
          <img src={back_arrow} className="headerBack" alt="back arrow" />
          </Link>
      </div>
      <div className="line"></div>
      <div className="aboutContent">
        <div className="aboutText">
          {aboutText}
        </div>
      </div>
    </div>
  );
  };
  
  export default AboutScreen;